import { Autocomplete, AutocompleteProps, TextField } from "@mui/material";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

type IProps<T, O> = {
	name: keyof T;
	options: O[];
	label: string;
	multiple?: boolean;
	disablePortal?: boolean;
	required?: boolean;
};

type Props<T, O> = IProps<T, O> &
	Omit<AutocompleteProps<O, boolean | undefined, boolean | undefined, boolean | undefined>, "renderInput">;

export default function RHFAutocomplete<T, O>({
	name,
	options,
	label,
	multiple = false,
	disablePortal = true,
	required = false,
	...other
}: Props<T, O>) {
	const { control, setValue } = useFormContext();
	const [inputValue, setInputValue] = useState("");
	return (
		<Controller
			name={name as string}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<Autocomplete
					multiple={multiple}
					disablePortal={disablePortal}
					fullWidth
					options={options}
					{...field}
					inputValue={inputValue}
					onInputChange={(event, newInputValue) => {
						setInputValue(newInputValue);
					}}
					onChange={(event, value) => {
						setValue(field.name, value, { shouldDirty: true, shouldValidate: true, shouldTouch: true });
					}}
					autoComplete
					{...other}
					renderInput={(params) => (
						<TextField {...params} required={required} label={label} error={!!error} helperText={error?.message} />
					)}
				/>
			)}
		/>
	);
}
