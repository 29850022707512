import { useAuth, useClerk, useUser } from "@clerk/nextjs";
import * as Sentry from "@sentry/browser";
import { makeOperation } from "@urql/core";
import { devtoolsExchange } from "@urql/devtools";
import { AuthConfig, authExchange } from "@urql/exchange-auth";
import { refocusExchange } from "@urql/exchange-refocus";
import { ReactNode, useCallback, useEffect } from "react";
import { cacheExchange, createClient, dedupExchange, fetchExchange, Provider } from "urql";

const { HASURA_BASE_URL } = process.env;

const addAuthToOperation: AuthConfig<any>["addAuthToOperation"] = ({ authState, operation }) => {
	if (!authState || !authState.token) {
		return operation;
	}

	const fetchOptions =
		typeof operation.context.fetchOptions === "function"
			? operation.context.fetchOptions()
			: operation.context.fetchOptions || {};

	return makeOperation(operation.kind, operation, {
		...operation.context,
		fetchOptions: {
			...fetchOptions,
			headers: {
				...fetchOptions.headers,
				Authorization: authState.token,
			},
		},
	});
};

const didAuthError: AuthConfig<any>["didAuthError"] = ({ error }) =>
	error.graphQLErrors.some((error) => error.extensions?.code === "UNAUTHORIZED");

const AuthorizedUrqlProvider = ({ children }: { children: ReactNode }) => {
	const { user } = useUser();
	const { getToken } = useAuth();
	const { session } = useClerk();

	const willAuthError: AuthConfig<any>["willAuthError"] = useCallback(
		({ authState }: { authState: any }) => {
			const exp = session?.lastActiveToken?.jwt.claims.exp;
			const now = Number(String(Date.now()).slice(0, -3));
			const isExpired = exp && exp <= now;
			if (!authState || isExpired) return true;
			return false;
		},
		[session]
	);

	const getAuth: AuthConfig<any>["getAuth"] = async ({ authState }) => {
		// if (!authState) {
		const token = `Bearer ${await getToken({ template: "hasura", leewayInSeconds: 10 })}`;
		return { token };
		// }
		// return null;
	};

	useEffect(() => {
		if (user) {
			Sentry.setUser({
				id: user.id,
				email: user.primaryEmailAddress?.emailAddress,
				username: user.fullName!,
			});
		} else {
			Sentry.setUser(null);
		}
	}, [user]);

	const client = createClient({
		url: `${HASURA_BASE_URL}/v1/graphql`,
		fetchOptions: () => {
			return {
				headers: {
					"X-Hasura-Role": "administrator",
				},
			};
		},
		exchanges: [
			authExchange({
				getAuth,
				addAuthToOperation,
				didAuthError,
				willAuthError,
			}),
			fetchExchange,
			dedupExchange,
			refocusExchange(),

			cacheExchange,
			devtoolsExchange,
		],
	});

	return <Provider value={client}>{children} </Provider>;
};

export default AuthorizedUrqlProvider;
