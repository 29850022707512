import { ThemeProvider, alpha, createTheme, useTheme } from "@mui/material/styles";
import { ReactNode, useMemo } from "react";

import ComponentsOverrides from "@theme/overrides";

import useSettings from "../../hooks/useSettings";

type ThemeColorPresetsProps = {
	children: ReactNode;
};

export default function ThemeColorPresets({ children }: ThemeColorPresetsProps) {
	const defaultTheme = useTheme();
	const { setColor } = useSettings();

	const themeOptions = useMemo(
		() => ({
			...defaultTheme,
			palette: {
				...defaultTheme.palette,
				primary: setColor,
			},
			customShadows: {
				...defaultTheme.customShadows,
				primary: `0 8px 16px 0 ${alpha(setColor.main, 0.24)}`,
			},
		}),
		[setColor, defaultTheme]
	);

	const theme = createTheme(themeOptions);

	theme.components = ComponentsOverrides(theme);

	return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
}
