import { Backdrop, Divider, Stack, Typography } from "@mui/material";
import { alpha, styled } from "@mui/material/styles";
import { AnimatePresence, m } from "framer-motion";
import { useEffect } from "react";

import Package from "../../../../package.json";
import { NAVBAR } from "../../../config";
import useSettings from "../../../hooks/useSettings";
import cssStyles from "../../../utils/cssStyles";
import { IconButtonAnimate, varFade } from "../../animate";
import Iconify from "../../other/Iconify";
import Scrollbar from "../../other/Scrollbar";
import BugReport from "../BugReport";

import SettingColorPresets from "./SettingColorPresets";
import SettingContrast from "./SettingContrast";
import SettingDirection from "./SettingDirection";
import SettingFullscreen from "./SettingFullscreen";
import SettingLayout from "./SettingLayout";
import SettingMode from "./SettingMode";
import SettingStretch from "./SettingStretch";

const RootStyle = styled(m.div)(({ theme }) => ({
	...cssStyles(theme).bgBlur({ color: theme.palette.background.paper, opacity: 0.92 }),
	top: 0,
	right: 0,
	bottom: 0,
	display: "flex",
	position: "fixed",
	overflow: "hidden",
	width: NAVBAR.BASE_WIDTH,
	flexDirection: "column",
	margin: theme.spacing(2),
	paddingBottom: theme.spacing(3),
	zIndex: theme.zIndex.drawer + 3,
	borderRadius: Number(theme.shape.borderRadius) * 1.5,
	boxShadow: `-24px 12px 32px -4px ${alpha(
		theme.palette.mode === "light" ? theme.palette.grey[500] : theme.palette.common.black,
		0.16
	)}`,
}));

export default function SettingsDrawer() {
	const { themeDirection, onResetSetting, open, setOpen } = useSettings();

	const varSidebar =
		themeDirection !== "rtl"
			? varFade({
					distance: NAVBAR.BASE_WIDTH,
					durationIn: 0.32,
					durationOut: 0.32,
			  }).inRight
			: varFade({
					distance: NAVBAR.BASE_WIDTH,
					durationIn: 0.32,
					durationOut: 0.32,
			  }).inLeft;

	useEffect(() => {
		document.body.style.overflow = open ? "hidden" : "";
	}, [open]);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Backdrop
				open={open}
				onClick={handleClose}
				sx={{ background: "transparent", zIndex: (theme) => theme.zIndex.drawer + 1 }}
			/>

			<AnimatePresence>
				{open && (
					<RootStyle {...varSidebar}>
						<Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ py: 2, pr: 1, pl: 2.5 }}>
							<Typography variant="subtitle1" sx={{ flexGrow: 1 }}>
								Instellingen
							</Typography>

							<IconButtonAnimate onClick={onResetSetting}>
								<Iconify icon="ic:round-refresh" width={20} height={20} />
							</IconButtonAnimate>

							<IconButtonAnimate onClick={handleClose}>
								<Iconify icon="eva:close-fill" width={20} height={20} />
							</IconButtonAnimate>
						</Stack>

						<Divider sx={{ borderStyle: "dashed" }} />

						<Scrollbar sx={{ flexGrow: 1 }}>
							<Stack spacing={3} sx={{ p: 3 }}>
								<Stack spacing={1.5}>
									<Typography variant="subtitle2">Modus</Typography>
									<SettingMode />
								</Stack>

								<Stack spacing={1.5}>
									<Typography variant="subtitle2">Contrast</Typography>
									<SettingContrast />
								</Stack>

								<Stack spacing={1.5}>
									<Typography variant="subtitle2">Richting</Typography>
									<SettingDirection />
								</Stack>

								<Stack spacing={1.5}>
									<Typography variant="subtitle2">Lay-out</Typography>
									<SettingLayout />
								</Stack>

								<Stack spacing={1.5}>
									<Typography variant="subtitle2">Kleur</Typography>
									<SettingColorPresets />
								</Stack>

								<Stack spacing={1.5}>
									<Typography variant="subtitle2">Uitrekken</Typography>
									<SettingStretch />
								</Stack>

								<SettingFullscreen />
								<BugReport />
								{Package.version && (
									<Typography
										sx={{
											display: "flex",
											flexGrow: 1,
											alignContent: "flex-end",
											flexDirection: "column",
										}}
										variant="caption"
									>{`Applicatieversie: ${Package.version}`}</Typography>
								)}
							</Stack>
						</Scrollbar>
					</RootStyle>
				)}
			</AnimatePresence>
		</>
	);
}
