import { Box, Grid, Stack, Skeleton } from "@mui/material";
import { ForwardedRef, forwardRef, useImperativeHandle, useRef } from "react";
import { UserDetailsField, UserFinancialField } from "src/type/user";
import { useQuery } from "urql";
import UserAttachmentForm from "./forms/UserAttachmentForm";
import UserDetailForm from "./forms/UserDetailForm";
import UserFinancialForm from "./forms/UserFinancialForm";
import { GET_USER_DETAILS, GET_USER_FINANCIAL } from "./GeneralGraphql";

export type UserFormRef = {
	isValid: boolean;
	submit(): Promise<void>;
};

export type ProfileRef = {
	financial: UserFormRef;
	details: UserFormRef;
	attachments: UserFormRef;
};

export type ProfileProps = {
	user_id: string;
	firstLogin?: boolean;
};

function Profile({ user_id, firstLogin }: ProfileProps, ref: ForwardedRef<ProfileRef>) {
	const financialRef = useRef<UserFormRef>(null);
	const detailsRef = useRef<UserFormRef>(null);
	const attachmentsRef = useRef<UserFormRef>(null);
	const [{ data: detailsResult }] = useQuery<UserDetailsField>({
		query: GET_USER_DETAILS,
		// pause: !user_id,
		variables: { user_id },
	});

	const [{ data: financialResult }] = useQuery<UserFinancialField>({
		query: GET_USER_FINANCIAL,
		variables: { user_id },
	});

	useImperativeHandle(ref, () => ({
		financial: {
			isValid: financialRef.current?.isValid ?? false,
			submit: async () => financialRef.current?.submit(),
		},
		details: {
			isValid: detailsRef.current?.isValid ?? false,
			submit: async () => detailsRef.current?.submit(),
		},
		attachments: {
			isValid: attachmentsRef.current?.isValid ?? false,
			submit: async () => attachmentsRef.current?.submit(),
		},
	}));

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12} sm={12} md={12} lg={12} xl={6}>
					<Stack spacing={3}>
						{detailsResult ? (
							<UserDetailForm
								ref={detailsRef}
								initialValues={detailsResult?.employee_user_details_by_pk}
								userId={user_id}
								removeSaveButton={firstLogin}
							/>
						) : (
							<Box sx={{ pt: 3 }}>
								<Skeleton variant="rounded" height={873} animation="wave" />
							</Box>
						)}
					</Stack>
				</Grid>

				<Grid item xs={12} lg={6}>
					{/* // TODO: Responsive by making Stack go to row in med screen sizes */}
					<Stack spacing={3}>
						{/* // TODO: Add ref to attachmentForm? */}
						<UserAttachmentForm />
						{financialResult ? (
							<UserFinancialForm
								ref={financialRef}
								initialValues={financialResult?.employee_user_financial_by_pk}
								userId={user_id}
								removeSaveButton={firstLogin}
							/>
						) : (
							<Skeleton variant="rounded" height={200} animation="wave" />
						)}
					</Stack>
				</Grid>
			</Grid>
		</>
	);
}

const ProfileContainer = forwardRef<ProfileRef, ProfileProps>(Profile);
export default ProfileContainer;
