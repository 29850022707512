import { useUser } from "@clerk/nextjs";
import { DialogAnimate } from "@components/animate";
import { Alert, Button, DialogTitle } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect, useState } from "react";
import { GET_CURRENTLY_SICK_ABSENCES_QUERY } from "src/type/schedule";
import { useQuery } from "urql";
import { GET_CURRENTLY_SICK_ABSENCES } from "./AbsenceGraphql";
import SubmitSicknessEndForm from "./SubmitSicknessEndForm";

export default function UserSickAlert() {
	const { user } = useUser();
	const { enqueueSnackbar } = useSnackbar();
	const [open, setOpen] = useState<boolean>(false);

	const [{ data: sicknessData, error: sicknessError }] = useQuery<GET_CURRENTLY_SICK_ABSENCES_QUERY>({
		query: GET_CURRENTLY_SICK_ABSENCES,
		variables: {
			user_id: user?.id,
		},
	});

	useEffect(() => {
		if (sicknessError) {
			enqueueSnackbar(`Er is een fout opgetreden bij ophalen van huidige verzuim. Probeer het later opnieuw.`, {
				variant: "error",
			});
		}
	}, [sicknessError]);

	const handleClickOpen = () => {
		setOpen(true);
	};

	const handleClose = () => {
		setOpen(false);
	};

	const startDate = sicknessData?.schedule_schedule_exception[0].start_datetime;
	const absenceId = sicknessData?.schedule_schedule_exception[0].id;

	return (
		<Alert
			sx={{ position: "absolute", top: 0, left: 0, right: 0, zIndex: 9999, mt: 3, mx: "30%" }}
			severity="warning"
			variant="outlined"
			action={
				<>
					<Button
						size="small"
						color="inherit"
						variant="contained"
						sx={{
							bgcolor: "common.white",
						}}
						onClick={handleClickOpen}
					>
						Beter melden
					</Button>
					{startDate && absenceId && (
						<DialogAnimate open={open} fullWidth onClose={handleClose}>
							<DialogTitle sx={{ display: "flex", justifyContent: "space-between" }}>Betermelding</DialogTitle>
							<SubmitSicknessEndForm absenceId={absenceId} start_datetime={startDate} onClose={handleClose} />
						</DialogAnimate>
					)}
				</>
			}
		>
			Je staat als ziek gemeld in het systeem. Wil je je beter melden?
		</Alert>
	);
}
