import { CommonUserFields } from "src/graphql/GeneralGraphql";
import { gql } from "urql";

const UserDetailFields = `
	user_id
	address
	birth_country
	birth_date
	bsn
	city
	country
	employee_number
	free_text
	full_first_name
	initials
	marital_status
	name_prefix
	nationality
	postal_code
	province
	salary
	sex
	manager
	seniority_date
	userByManager {
		${CommonUserFields}
	}
`;

export const UPDATE_USER_DETAILS = gql`
	mutation UPDATE_USER_DETAILS($user_id: String!, $_set: employee_user_details_set_input!) {
		update_employee_user_details_by_pk(pk_columns: { user_id: $user_id }, _set: $_set) {
			${UserDetailFields}
		}
	}
`;

export const GET_MANAGER_OPTIONS = gql`
	query GET_MANAGER_OPTIONS {
		employee_user {
			${CommonUserFields}
		}
	}
`;

export const UPSERT_USER_FINANCIAL = gql`
	mutation UPSERT_USER_FINANCIAL(
		$object: employee_user_financial_insert_input!
		$update_columns: [employee_user_financial_update_column!] = [bic, country, iban]
	) {
		insert_employee_user_financial_one(
			object: $object
			on_conflict: { constraint: user_financial_pkey, update_columns: $update_columns }
		) {
			user_id
			bic
			country
			iban
		}
	}
`;

export const GET_USER_DETAILS = gql`
	query GET_USER_DETAILS($user_id: String!) {
		employee_user_details_by_pk(user_id: $user_id) {
			${UserDetailFields}
		}
	}
`;

export const GET_USER_FINANCIAL = gql`
	query GET_USER_FINANCIAL($user_id: String!) {
		employee_user_financial_by_pk(user_id: $user_id) {
			iban
			bic
			country
		}
	}
`;

export const GET_EMPLOYEE_PROFILE = gql`
	query GET_EMPLOYEE_PROFILE($id: String!) {
		employee_user_by_pk(id: $id) {
			${CommonUserFields}
			user_detail {
				active_job_titles
			}
		}
	}
`;

export const GET_PROFILE_CRUMBS = gql`
	query GET_PROFILE_CRUMBS($id: String!) {
		employee_user_by_pk(id: $id) {
			full_name
		}
	}
`;

export const CREATE_CLERK_USER = gql`
	mutation CREATE_CLERK_USER(
		$emailAddress: String!
		$firstName: String!
		$lastName: String!
		$organisation: String!
		$role: String!
	) {
		create_clerk_user(
			emailAddress: $emailAddress
			firstName: $firstName
			lastName: $lastName
			organisation: $organisation
			role: $role
		) {
			hasuraUser
			message
		}
	}
`;

export const INSERT_USER_DETAILS = gql`
	mutation INSERT_USER_DETAILS($object: employee_user_details_insert_input!) {
		insert_employee_user_details_one(object: $object) {
			user_id
			employee_number
			seniority_date
			manager
		}
	}
`;
