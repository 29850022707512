import { FormProvider } from "@components/hook-form";
import { Grid, Stack, Card, Typography } from "@mui/material";
import { useSnackbar } from "notistack";
import { object } from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { UserDetail } from "src/type/user";
import { getDirtyFields } from "@utils/rhf";
import AttachmentList from "../AttachmentList";

type UserAttachmentFormProps = {
	initialValues?: UserDetail;
};

export default function UserAttachmentForm({ initialValues }: UserAttachmentFormProps) {
	const { enqueueSnackbar } = useSnackbar();

	// const [{ fetching: updateFetching, data: updateData }, updateUserDetails] = useMutation<UserDetailsField>(
	// 	UPDATE_USER_DETAILS_ATTACHMENTS
	// );

	const UserAttachmentSchema = object().shape({
		// TODO: Add schema validation
	});

	const methods = useForm<UserDetail>({
		resolver: yupResolver(UserAttachmentSchema),
		defaultValues: initialValues,
	});

	const {
		reset,
		watch,
		handleSubmit,
		formState: { isDirty, isValid, dirtyFields },
	} = methods;

	const onSubmit = (details: UserDetail) => {
		const dirty = getDirtyFields<UserDetail>(details, dirtyFields);
		// updateUserDetails({ user_id: initialValues?.user_id, _set: dirty });
		reset({ ...details });
	};

	// useEffect(() => {
	// 	if (updateData) {
	// 		enqueueSnackbar("Succesvol bijgewerkt");
	// 	}
	// }, [updateData]);

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={3} pt={3}>
				<Grid item xs={12} md={12} lg={12}>
					<Stack spacing={3}>
						<Card sx={{ p: 3 }}>
							<Typography variant="h6">Identiteitsbewijzen</Typography>
							{/* //TODO: maxsize? & responsiveness */}
							<AttachmentList primaryText="Paspoort" secondaryText="Geüploaded op 8-9-22" uploaded />
							<AttachmentList primaryText="Identificatiebewijs" secondaryText="Nog niet geüploaded" uploaded={false} />
							<AttachmentList primaryText="Rijbewijs" secondaryText="Geüploaded op 5-3-21" uploaded />
						</Card>
					</Stack>
				</Grid>
			</Grid>
		</FormProvider>
	);
}
