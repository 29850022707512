import { Box, BoxProps } from "@mui/material";
import Head from "next/head";
import { ReactNode, forwardRef } from "react";

const { APP_NAME } = process.env;

interface Props extends BoxProps {
	children: ReactNode;
	meta?: ReactNode;
	title: string;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = "", meta, ...other }, reference) => (
	<>
		<Head>
			<title>{`${title} | ${APP_NAME}`}</title>
			{meta}
		</Head>

		<Box ref={reference} {...other}>
			{children}
		</Box>
	</>
));

export default Page;
