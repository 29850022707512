import { UserButton, useUser } from "@clerk/nextjs";
import Profile, { ProfileRef } from "@features/profile/general/ProfileContainer";
import { Box, Container, Tooltip } from "@mui/material";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useRef, useState } from "react";
import Page from "@components/other/Page";
import { Alert, LoadingButton } from "@mui/lab";
import { useRouter } from "next/router";
import { useMutation } from "urql";
import { UPDATE_FIRST_LOGIN_MUTATION } from "src/type/dashboard";
import { UPDATE_FIRST_LOGIN } from "@features/dashboard/DashboardGraphql";
import { useSnackbar } from "notistack";

export default function FirstLoginComponent() {
	const { user } = useUser();
	const { reload } = useRouter();
	const profileRef = useRef<ProfileRef>(null);
	const [loading, setLoading] = useState<boolean>(false);
	const { enqueueSnackbar } = useSnackbar();
	const [state, updateFirstLoginStatus] = useMutation<UPDATE_FIRST_LOGIN_MUTATION>(UPDATE_FIRST_LOGIN);

	const submitForms = async () => {
		setLoading(true);
		if (!profileRef.current?.details.isValid) await profileRef.current?.details.submit();
		if (!profileRef.current?.financial.isValid) await profileRef.current?.financial.submit();
		if (!profileRef.current?.details.isValid || !profileRef.current?.financial.isValid) {
			setLoading(false);
			return;
		}

		updateFirstLoginStatus({ userId: user?.id, firstLogin: false })
			.then((result) => {
				if (result.error)
					enqueueSnackbar("Er is een fout opgetreden, probeer het later opnieuw.", { variant: "error" });
				if (result.data) {
					enqueueSnackbar("Account succesvol ingesteld, je wordt doorgestuurd naar het dashboard.");
					// We reload the page instead of pushing a route, else Clerk metadata update is not triggered yet.
					return reload();
				}
			})
			.catch((error) => {
				enqueueSnackbar("Er is een fout opgetreden bij instellen van het account. Probeer het later opnieuw.", {
					variant: "error",
				});
				// eslint-disable-next-line @typescript-eslint/no-unsafe-argument -- Typescript does not handle errors with typing (https://stackoverflow.com/questions/54649465/how-to-do-try-catch-and-finally-statements-in-typescript)
				throw new Error(error);
			});
		setLoading(false);
	};

	return (
		<Page title="Eerste inlog">
			<Tooltip arrow title="Valideer gegevens en ga door naar de applicatie">
				<LoadingButton
					loading={loading}
					disabled={loading}
					variant="contained"
					color="primary"
					onClick={submitForms}
					sx={{ position: "fixed", bottom: 32, right: 32, zIndex: 9999 }}
					endIcon={<NavigateNextIcon />}
				>
					Verder
				</LoadingButton>
			</Tooltip>
			<Tooltip arrow title="Account">
				<Box sx={{ position: "fixed", top: 32, right: 32, zIndex: 9999 }}>
					<UserButton />
				</Box>
			</Tooltip>
			<Container>
				<Alert severity="info" sx={{ mt: 2 }}>
					Vul je gegevens in om verder te gaan naar de applicatie.
				</Alert>
				{user && <Profile ref={profileRef} user_id={user?.id} firstLogin />}
			</Container>
		</Page>
	);
}
