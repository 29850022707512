import { ClerkLoaded, ClerkLoading, ClerkProvider, SignedIn, SignedOut } from "@clerk/nextjs";
import { MotionLazyContainer } from "@components/animate";
import {
	FirstLogin,
	UserIsSick,
	OrganizationActive,
	OrganizationAvailable,
	OrganizationInactive,
	OrganizationNotAvailable,
	RedirectTo503,
	RedirectToSelectOrg,
	RedirectToSignIn,
	RegularLogin,
} from "@components/clerk/Guards";
import LoadingScreen from "@components/other/LoadingScreen";
import ProgressBar from "@components/other/ProgressBar";
import ThemeSettings from "@components/settings";
import { SettingsValueProps } from "@components/settings/type";
import AuthorizedUrqlProvider from "@contexts/AuthorizedUrqlProvider";
import { CollapseDrawerProvider } from "@contexts/CollapseDrawerContext";
import NotistackProvider from "@contexts/NotistackProvider";
import { SettingsProvider } from "@contexts/SettingsContext";
import FirstLoginComponent from "@features/dashboard/FirstLoginComponent";
import UserSickAlert from "@features/profile/absence/UserSickAlert";
import { LicenseInfo } from "@mui/x-license-pro";
import { CLERK_APPEARANCE } from "@utils/clerk";
import { NextPage } from "next";
import { appWithTranslation } from "next-i18next";
import { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import { FC, ReactElement, ReactNode } from "react";
import "react-lazy-load-image-component/src/effects/black-and-white.css";
import "react-lazy-load-image-component/src/effects/blur.css";
import "react-lazy-load-image-component/src/effects/opacity.css";
import "simplebar/src/simplebar.css";
import { defaultSettings } from "src/config";
import ThemeProvider from "src/theme";

type NextPageWithLayout = NextPage & {
	getLayout?: (page: ReactElement) => ReactNode;
};

interface MyAppProps extends AppProps {
	settings: SettingsValueProps;
	Component: NextPageWithLayout;
}

export const PUBLIC_PAGES = ["/auth/sign-in", "/auth/sign-up", "/auth/select-organization"];

function MyApp(props: MyAppProps) {
	const { Component, pageProps } = props;
	const { pathname } = useRouter();
	const getLayout = Component.getLayout ?? ((page: any) => page);
	const isPublicPage = PUBLIC_PAGES.includes(pathname);

	LicenseInfo.setLicenseKey(process.env.MUI_X_PRO!);

	return (
		<>
			<Head>
				<meta name="viewport" content="initial-scale=1, width=device-width" />
			</Head>
			<ClerkProvider {...pageProps} clerkJSVariant={""} appearance={CLERK_APPEARANCE}>
				<MotionLazyContainer>
					<SettingsProvider defaultSettings={defaultSettings}>
						<ThemeProvider>
							<ThemeSettings>
								<ClerkLoading>
									<LoadingScreen />
								</ClerkLoading>
								{isPublicPage && getLayout(<Component {...pageProps} />)}
								{!isPublicPage && (
									<NotistackProvider>
										<ProgressBar />
										<ClerkLoaded>
											<>
												<SignedIn>
													<OrganizationActive>
														<CollapseDrawerProvider>
															<AuthorizedUrqlProvider>
																<FirstLogin>
																	<FirstLoginComponent />
																</FirstLogin>
																<RegularLogin>
																	<UserIsSick>
																		<UserSickAlert />
																	</UserIsSick>
																	{getLayout(<Component {...pageProps} />)}
																</RegularLogin>
															</AuthorizedUrqlProvider>
														</CollapseDrawerProvider>
													</OrganizationActive>
													<OrganizationInactive>
														<OrganizationAvailable>
															<RedirectToSelectOrg />
														</OrganizationAvailable>
														<OrganizationNotAvailable>
															<RedirectTo503 />
														</OrganizationNotAvailable>
													</OrganizationInactive>
												</SignedIn>
												<SignedOut>
													<RedirectToSignIn />
												</SignedOut>
											</>
										</ClerkLoaded>
									</NotistackProvider>
								)}
							</ThemeSettings>
						</ThemeProvider>
					</SettingsProvider>
				</MotionLazyContainer>
			</ClerkProvider>
		</>
	);
}

export default appWithTranslation(MyApp as FC);
