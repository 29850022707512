export const ROOT_PATH = "";
export const API_ROOT_PATH = "/api";
export const BUG_REPORT_URL = "https://profitflow.nl/contact";

function path(root: string, sublink: string) {
	return `${root}${sublink}`;
}

// TODO: Flesh this out, like disallow subTab if no tab is selected
function profilePath(id?: string, tab?: string, subTab?: string) {
	return `/user/profile${id ? `?id=${id}` : ""}${tab ? `${id ? "&" : "?"}tab=${tab}` : ""}${
		subTab ? `&subtab=${subTab}` : ""
	}`;
}

export const ROUTES = {
	root: ROOT_PATH,
	home: {
		root: path(ROOT_PATH, "/"),
	},
	item: {
		root: path(ROOT_PATH, "/item"),
		list: path(ROOT_PATH, "/item/list"),
		history: path(ROOT_PATH, "/item/history"),
	},
	contract: {
		root: path(ROOT_PATH, "/contract"),
		list: path(ROOT_PATH, "/contract/list"),
	},
	course: {
		root: path(ROOT_PATH, "/course"),
		list: path(ROOT_PATH, "/course/list"),
	},
	declaration: {
		root: path(ROOT_PATH, "/declaration"),
		list: path(ROOT_PATH, "/declaration/list"),
	},
	absence: {
		root: path(ROOT_PATH, "/absence"),
		list: path(ROOT_PATH, "/absence/list"),
	},
	sickness: {
		root: path(ROOT_PATH, "/sickness"),
		list: path(ROOT_PATH, "/sickness/list"),
	},
	competence: {
		root: path(ROOT_PATH, "/competence"),
		list: path(ROOT_PATH, "/competence/list"),
	},
	user: {
		root: {
			path: path(ROOT_PATH, "/user"),
			roles: ["administrator", "manager", "employee"],
		},
		list: {
			path: path(ROOT_PATH, "/user/list"),
			roles: ["administrator", "manager"], // TODO: Only allow managers to see employees of their own employee team
		},
		create: {
			path: path(ROOT_PATH, "/user/create"),
			roles: ["administrator"],
		},
		profile: {
			path: (id?: string, tab?: string, subTab?: string) => path(ROOT_PATH, profilePath(id, tab, subTab)),
			roles: ["administrator", "manager", "employee"],
		},
	},
	workflow: {
		root: path(ROOT_PATH, `/workflow`),
		list: path(ROOT_PATH, `/workflow/list`),
		edit: (id: string | number) => path(ROOT_PATH, `/workflow/${id}/edit`),
	},
	tasks: {
		root: path(ROOT_PATH, "/tasks"),
		list: path(ROOT_PATH, "/tasks/list"),
		create: path(ROOT_PATH, "/tasks/create"),
		group: (id: string | number) => path(ROOT_PATH, `/tasks/list?tab=${id}`),
	},
	calendar: {
		root: path(ROOT_PATH, "/calendar"),
		overview: path(ROOT_PATH, "/calendar/overview"),
	},
	api: {
		root: API_ROOT_PATH,
	},
	auth: {
		root: path(API_ROOT_PATH, "/auth"),
		signin: path(ROOT_PATH, "/auth/sign-in"),
		signup: path(ROOT_PATH, "/auth/sign-up"),
		select_org: path(ROOT_PATH, "/auth/select-organization"),
	},
	404: {
		root: path(ROOT_PATH, "/404"),
	},
	403: {
		root: path(ROOT_PATH, "/403"),
	},
	503: {
		root: path(ROOT_PATH, "/503"),
	},
};
