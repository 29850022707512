import { gql } from "urql";

export const UPDATE_FIRST_LOGIN = gql`
	mutation UPDATE_FIRST_LOGIN($userId: String!, $firstLogin: Boolean!) {
		update_first_login(firstLogin: $firstLogin, userId: $userId) {
			message
			userId
			firstLogin
		}
	}
`;
