import { ClearSicknessType } from "src/type/schedule";
import { object, date, boolean, string, number, ref, SchemaOf } from "yup";

export const AbsenceSchema = object().shape({
	start_datetime: date().required().typeError("Startdatum moet een geldige datum zijn").label("Startdatum"),
	end_datetime: date()
		.min(ref("start_datetime"), "Verwachte einddatum kan niet voor startdatum liggen!")
		.required()
		.typeError("Verwachte einddatum moet een geldige datum zijn")
		.label("Verwachte einddatum"),
	updated_end_datetime: date()
		.min(ref("start_datetime"), "Werkelijke einddatum kan niet voor startdatum liggen!")
		.typeError("Werkelijke einddatum moet een geldige datum zijn")
		.optional()
		.nullable()
		.label("Werkelijke einddatum"),
	approved: boolean().optional().nullable().label("Status"),
	description: string().optional().nullable().label("Omschrijving"),
	hours: number().when("schedule_exception_type.name", {
		is: "Tijd voor tijd",
		then: number().min(0).required().label("Aantal uren"),
		otherwise: number().min(0).optional().label("Aantal uren"),
	}),
	schedule_exception_type: object()
		.shape({
			id: number().required(),
		})
		.required()
		.label("Type"),
	selectedUser: object()
		.shape({ id: string().required(), full_name: string().required() })
		.required()
		.label("Toegewezen medewerker"),
});

export const SubmitSicknessEndSchema: SchemaOf<ClearSicknessType> = object().shape({
	start_datetime: date().required().typeError("Startdatum moet een geldige datum zijn").label("Startdatum"),
	updated_end_datetime: date()
		.min(ref("start_datetime"), "Werkelijke einddatum kan niet voor startdatum liggen!")
		.typeError("Werkelijke einddatum moet een geldige datum zijn")
		.required()
		.label("Werkelijke einddatum"),
});
