import { SchemaOf, object, number, date, string } from "yup";
import { UserDetailFormInput } from "./UserDetailForm";

export const UserDetailsSchema: SchemaOf<UserDetailFormInput> = object().shape({
	employee_number: number().required().label("Personeelsnummer"),
	birth_date: date().typeError("Geboortedatum moet een geldige datum zijn").defined().nullable().label("Geboortedatum"),
	salary: number().defined().nullable().label("Salaris"),
	seniority_date: date()
		.typeError("Anciënniteitsdatum moet een geldige datum zijn")
		.defined()
		.nullable()
		.label("Anciënniteitsdatum"),
	userByManager: object()
		.shape({
			id: string().required(),
			full_name: string().required(),
		})
		.nullable()
		.label("Manager"),
});

export const UserFinancialSchema = object().shape({
	bic: string().required().nullable().label("BIC"),
	country: string().required().nullable().label("Land"),
	iban: string().required().nullable().label("IBAN"),
});
