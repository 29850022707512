import { ListItem, ListItemButton, ListItemIcon, ListItemText, Tooltip } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import DeleteIcon from "@mui/icons-material/Delete";
import Avatar from "@mui/material/Avatar";
import { useTheme } from "@mui/material/styles";
import PriorityHighIcon from "@mui/icons-material/PriorityHigh";
import CheckIcon from "@mui/icons-material/Check";
import DataGridActions, { TableRowAction } from "@components/datagrid/DataGridActions";

type AttachmentListProps = {
	primaryText: string;
	secondaryText: string;
	uploaded: boolean;
};

type FixLater = {
	a: any;
};

export default function AttachmentList({ primaryText, secondaryText, uploaded }: AttachmentListProps) {
	const theme = useTheme();

	// TODO: Flesh this out, make a type for this & fix disabled on icon button not greying out
	const actions: TableRowAction<FixLater>[] = [
		{
			name: "download",
			label: "Downloaden",
			disabled: false,
			color: "success",
			icon: <FileDownloadIcon width={20} height={20} />,
			link: (user) => "",
		},
		{
			name: "delete",
			label: "Verwijderen",
			disabled: uploaded,
			color: "error",
			icon: <DeleteIcon width={20} height={20} />,
			onClick: (absence) => console.log("Clicked attachment deletion"),
		},
	];

	return (
		<ListItem disablePadding secondaryAction={<DataGridActions object={{ a: "test" }} actions={actions} />}>
			<ListItemButton onClick={(event) => {}}>
				<ListItemIcon>
					{uploaded ? (
						<Tooltip arrow title="Succesvol geüpload">
							<Avatar sx={{ bgcolor: theme.palette.background.neutral }}>
								<CheckIcon color="success" />
							</Avatar>
						</Tooltip>
					) : (
						<Tooltip arrow title="Ontbreekt nog, graag s.v.p. uploaden!">
							<Avatar sx={{ bgcolor: theme.palette.background.neutral }}>
								<PriorityHighIcon color="info" />
							</Avatar>
						</Tooltip>
					)}
				</ListItemIcon>
				<ListItemText primary={primaryText} secondary={secondaryText} />
			</ListItemButton>
		</ListItem>
	);
}
