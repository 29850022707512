import { useUser } from "@clerk/nextjs";
import { UserResource } from "@clerk/types";
import { Dictionary, cloneDeep } from "lodash";
import { Dispatch, ReactNode, SetStateAction, createContext, useCallback, useEffect, useState } from "react";

import {
	SettingsContextProps,
	SettingsValueProps,
	ThemeColorPresets,
	ThemeContrast,
	ThemeDirection,
	ThemeLayout,
	ThemeMode,
} from "@components/settings/type";
import { usePrevious } from "@hooks/usePrevious";
import getColorPresets, { colorPresets, defaultPreset } from "@utils/getColorPresets";
import { getJsonDifference } from "@utils/getJsonDifference";
import { defaultSettings } from "src/config";

const initialState: SettingsContextProps = {
	...defaultSettings,

	// Mode
	onToggleMode: () => {},
	onChangeMode: () => {},

	// Direction
	onToggleDirection: () => {},
	onChangeDirection: () => {},
	onChangeDirectionByLang: () => {},

	// Layout
	onToggleLayout: () => {},
	onChangeLayout: () => {},

	// Contrast
	onToggleContrast: () => {},
	onChangeContrast: () => {},

	// Color
	onChangeColor: () => {},
	setColor: defaultPreset,
	colorOption: [],

	// Stretch
	onToggleStretch: () => {},

	// Reset
	onResetSetting: () => {},

	open: false,
	setOpen: () => {},
};

const SettingsContext = createContext(initialState);

type SettingsProviderProps = {
	children: ReactNode;
	defaultSettings: SettingsValueProps;
};

function SettingsProvider({ children, defaultSettings }: SettingsProviderProps) {
	const [settings, setSettings] = useSettingCookies(defaultSettings);
	const [open, setOpen] = useState(defaultSettings.open);
	const langStorage = typeof window !== "undefined" ? localStorage.getItem("i18nextLng") : "";

	const isArabic = langStorage === "ar";

	useEffect(() => {
		if (isArabic) {
			onChangeDirectionByLang("ar");
		}
	}, [isArabic]);

	// Mode

	const onToggleMode = () => {
		setSettings({
			...settings,
			themeMode: settings.themeMode === "light" ? "dark" : "light",
		});
	};

	const onChangeMode = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeMode: (event.target as HTMLInputElement).value as ThemeMode,
		});
	};

	// Direction

	const onToggleDirection = () => {
		setSettings({
			...settings,
			themeDirection: settings.themeDirection === "rtl" ? "ltr" : "rtl",
		});
	};

	const onChangeDirection = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeDirection: (event.target as HTMLInputElement).value as ThemeDirection,
		});
	};

	const onChangeDirectionByLang = (lang: string) => {
		setSettings({
			...settings,
			themeDirection: lang === "ar" ? "rtl" : "ltr",
		});
	};

	// Layout

	const onToggleLayout = () => {
		setSettings({
			...settings,
			themeLayout: settings.themeLayout === "vertical" ? "horizontal" : "vertical",
		});
	};

	const onChangeLayout = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeLayout: (event.target as HTMLInputElement).value as ThemeLayout,
		});
	};

	// Contrast

	const onToggleContrast = () => {
		setSettings({
			...settings,
			themeContrast: settings.themeContrast === "default" ? "bold" : "default",
		});
	};

	const onChangeContrast = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeContrast: (event.target as HTMLInputElement).value as ThemeContrast,
		});
	};

	// Color

	const onChangeColor = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSettings({
			...settings,
			themeColorPresets: (event.target as HTMLInputElement).value as ThemeColorPresets,
		});
	};

	// Stretch

	const onToggleStretch = () => {
		setSettings({
			...settings,
			themeStretch: !settings.themeStretch,
		});
	};

	// Reset

	const onResetSetting = () => {
		setSettings({
			themeMode: initialState.themeMode,
			themeLayout: initialState.themeLayout,
			themeStretch: initialState.themeStretch,
			themeContrast: initialState.themeContrast,
			themeDirection: initialState.themeDirection,
			themeColorPresets: initialState.themeColorPresets,
			open: initialState.open,
		});
	};

	return (
		<SettingsContext.Provider
			// eslint-disable-next-line react/jsx-no-constructed-context-values
			value={{
				...settings,

				// Mode
				onToggleMode,
				onChangeMode,

				// Direction
				onToggleDirection,
				onChangeDirection,
				onChangeDirectionByLang,

				// Layout
				onToggleLayout,
				onChangeLayout,

				// Contrast
				onChangeContrast,
				onToggleContrast,

				// Stretch
				onToggleStretch,

				// Color
				onChangeColor,
				setColor: getColorPresets(settings.themeColorPresets),
				colorOption: colorPresets.map((color) => ({
					name: color.name,
					value: color.main,
				})),

				open,
				setOpen,

				// Reset
				onResetSetting,
			}}
		>
			{children}
		</SettingsContext.Provider>
	);
}

export { SettingsContext, SettingsProvider };

function useSettingCookies(
	defaultSettings: SettingsValueProps
): [SettingsValueProps, Dispatch<SetStateAction<SettingsValueProps>>] {
	const { user, isSignedIn } = useUser();
	const [settings, setSettings] = useState<SettingsValueProps>(defaultSettings);
	const previousSettings = usePrevious<SettingsValueProps>(settings);

	const setClerkData = useCallback(
		async (
			user: UserResource | undefined,
			key: keyof SettingsValueProps,
			value: SettingsValueProps[keyof SettingsValueProps]
		) => {
			if (user) {
				const unsafeMetadata = user.unsafeMetadata as Record<string, any>;
				const settings = unsafeMetadata.settings as SettingsValueProps;
				const isSame = settings && settings[key] === value;
				if (!isSame) {
					return user.update({
						unsafeMetadata: {
							...unsafeMetadata,

							settings: {
								...settings,
								[key]: value,
							},
						},
					});
				}
			}
		},
		[user]
	);

	const parseClerkSettings = useCallback(
		(settings: SettingsValueProps, defaultSettings: SettingsValueProps) => {
			let parsed = cloneDeep(settings);
			const entries = Object.entries(defaultSettings);
			for (const [k, v] of entries) {
				const key = k as keyof SettingsValueProps;
				const value = v;
				if (typeof value !== "string") {
					parsed = {
						...parsed,
						[key]: Boolean(settings[key]),
					};
				}
			}
			return parsed;
		},
		[settings]
	);

	const onChangeSetting = useCallback(
		async (changes: Dictionary<SettingsValueProps>) => {
			if (!isSignedIn) {
				return;
			}
			let newUser: UserResource | undefined = user;
			const entries = Object.entries(changes);
			for await (const [k, v] of entries) {
				const key = k as keyof SettingsValueProps;
				const value = v as unknown as SettingsValueProps[keyof SettingsValueProps];
				newUser = await setClerkData(newUser, key, value);
			}
		},
		[isSignedIn]
	);

	useEffect(() => {
		if (isSignedIn && user && user.unsafeMetadata.settings) {
			const unsafeMetadata = user?.unsafeMetadata as Record<string, any>;

			// @ts-ignore unSafeMetaData is unknown, but settings is known type
			const { settings }: { settings: SettingsValueProps } = unsafeMetadata;
			let parsedSettings: Partial<SettingsValueProps> = {};
			if (settings) {
				parsedSettings = parseClerkSettings(settings, defaultSettings);
			}
			setSettings({
				...defaultSettings,
				...parsedSettings,
			});
		}
	}, [isSignedIn]);

	useEffect(() => {
		if (previousSettings) {
			const changes = getJsonDifference<SettingsValueProps>(settings, previousSettings);
			if (changes) {
				onChangeSetting(changes);
			}
		}
	}, [settings]);

	return [settings, setSettings];
}
