import { ClerkProviderProps } from "@clerk/nextjs";
import { ContextWithAuth } from "@clerk/nextjs/dist/middleware/types";
import { GraphQLClient } from "graphql-request";

const { BASE_URL } = process.env;
// eslint-disable-next-line unicorn/consistent-destructuring -- This is a valid use case which breaks Clerk if merged with the destructuring above.
const IS_PROD = process.env.NODE_ENV === "production";

export const getGraphQLClient = async (context: ContextWithAuth) => {
	const { HASURA_BASE_URL } = process.env;

	const { getToken, userId } = context.req.auth;

	if (!userId) return false;
	const hasuraToken = await getToken({ template: "hasura" });
	if (!hasuraToken) return false;
	const graphQlClient = new GraphQLClient(`${HASURA_BASE_URL}/v1/graphql`, {
		headers: {
			authorization: `Bearer ${hasuraToken}`,
		},
		errorPolicy: "ignore",
	});
	return graphQlClient;
};

export const CLERK_APPEARANCE: ClerkProviderProps["appearance"] = {
	variables: {
		colorPrimary: "#222ac7",
		borderRadius: "8px",
		fontFamily: "Raleway, sans-serif",
		fontWeight: {
			normal: 400,
			medium: 500,
			bold: 600,
		},
	},
	layout: {
		socialButtonsVariant: "iconButton",
		helpPageUrl: IS_PROD ? `${BASE_URL}/help` : undefined,
		privacyPageUrl: IS_PROD ? `${BASE_URL}/privacy` : undefined,
		termsPageUrl: IS_PROD ? `${BASE_URL}/term` : undefined,
		socialButtonsPlacement: "top",
		showOptionalFields: true,
	},
	elements: {
		card: {
			borderRadius: "32px",
		},
		logoImage: {
			filter: "hue-rotate(342deg) brightness(1.4)",
		},
		main: {
			gap: "1.5rem",
		},
		headerTitle: {
			fontSize: "24px",
		},
		headerSubtitle: {
			fontSize: "16px",
		},
		socialButtons: {
			display: "flex",
		},
		socialButtonsIconButton: {
			borderRadius: "100%",
			padding: 18,
		},
		dividerBox: {
			display: "none",
		},
		formFieldInput: {
			borderRadius: "100px",
		},
		formButtonPrimary: {
			borderRadius: "100px",
			textTransform: "none",
		},
		footerActionLink: {
			fontWeight: 500,
		},
	},
	signIn: {
		elements: {
			logoBox: {
				width: "100%",
			},
			logoImage: {
				height: 25,
			},
		},
	},
	signUp: {
		elements: {
			logoBox: {
				width: "100%",
			},
			logoImage: {
				height: 25,
			},
		},
	},
};
