import { TypographyVariant, useTheme } from "@mui/material/styles";

import useResponsive from "../hooks/useResponsive";

export default function GetFontValue(variant: TypographyVariant) {
	const theme = useTheme();

	const breakpoints = useWidth();

	const key = theme.breakpoints.up(breakpoints === "xl" ? "lg" : breakpoints);

	const hasResponsive =
		variant === "h1" ||
		variant === "h2" ||
		variant === "h3" ||
		variant === "h4" ||
		variant === "h5" ||
		variant === "h6";

	const getFont: any =
		hasResponsive && theme.typography[variant][key] ? theme.typography[variant][key] : theme.typography[variant];

	// eslint-disable-next-line @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access
	const fontSize = remToPx(getFont.fontSize);

	const lineHeight = Number(theme.typography[variant].lineHeight) * fontSize;

	const { fontWeight, letterSpacing } = theme.typography[variant];

	return { fontSize, lineHeight, fontWeight, letterSpacing };
}

export function remToPx(value: string) {
	return Math.round(parseFloat(value) * 16);
}

export function pxToRem(value: number) {
	return `${value / 16}rem`;
}

export function responsiveFontSizes({ sm, md, lg }: { sm: number; md: number; lg: number }) {
	return {
		"@media (min-width:600px)": {
			fontSize: pxToRem(sm),
		},
		"@media (min-width:900px)": {
			fontSize: pxToRem(md),
		},
		"@media (min-width:1200px)": {
			fontSize: pxToRem(lg),
		},
	};
}

function useWidth() {
	const theme = useTheme();

	const keys = [...theme.breakpoints.keys].reverse();

	return (
		// eslint-disable-next-line unicorn/no-array-reduce -- Minimals feels like reduce is the best way to do this
		keys.reduce((output, key) => {
			const matches = useResponsive("up", key);

			return !output && matches ? key : output;
		}) || "xs"
	);
}
