import { Theme } from "@mui/material/styles";

export default function Container(theme: Theme) {
	return {
		MuiContainer: {
			...(theme.components ? theme.components.MuiContainer : undefined),
			styleOverrides: {
				root: {},
			},
		},
	};
}
