import { LazyMotion } from "framer-motion";
import { ReactNode } from "react";

// eslint-disable-next-line import/extensions
const loadFeatures = () => import("./features.js").then((response) => response.default);

type Props = {
	children: ReactNode;
};

export default function MotionLazyContainer({ children }: Props) {
	return (
		<LazyMotion strict features={loadFeatures}>
			{children}
		</LazyMotion>
	);
}
