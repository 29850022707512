import { Tooltip } from "@mui/material";
import Avatar, { Props as AvatarProps } from "./Avatar";
import createAvatar from "./createAvatar";

type AnyAvatarProps = AvatarProps & {
	name: string;
	picture: string | null;
};

export default function AnyAvatar({ name, picture, ...other }: AnyAvatarProps) {
	if (!name) {
		return null;
	}

	return (
		<Tooltip title={name} arrow>
			<Avatar src={picture ?? ""} alt={name} color={picture ? "default" : createAvatar(name).color} {...other}>
				{createAvatar(name).name}
			</Avatar>
		</Tooltip>
	);
}
