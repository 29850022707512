import { SxProps } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { ReactNode } from "react";
import { FieldValues, FormProvider as Form, UseFormReturn } from "react-hook-form";

type Props<T> = {
	children: ReactNode;
	methods: UseFormReturn<T & FieldValues>;
	onSubmit?: VoidFunction | Promise<void>;
	sx?: SxProps<Theme>;
};

export default function FormProvider<T>({ children, onSubmit, methods }: Props<T>) {
	return (
		<Form {...methods}>
			{/* Workaround bc html form does not accept promisified rhf form onSubmit */}
			<form onSubmit={onSubmit as VoidFunction}>{children}</form>
		</Form>
	);
}
