import { FormProvider, RHFTextField } from "@components/hook-form";
import { Card, Stack, Box, Typography } from "@mui/material";
import { UserFinancial, UserFinancialField } from "src/type/user";
import { LoadingButton } from "@mui/lab";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { useMutation } from "urql";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle } from "react";
import { UPSERT_USER_FINANCIAL } from "../GeneralGraphql";
import { UserFormRef } from "../ProfileContainer";
import { UserFinancialSchema } from "./ProfileGeneralSchemas";

type UserFinancialFormProps = {
	initialValues: UserFinancial | null;
	userId: string;
	removeSaveButton?: boolean;
};

function Financial(
	{ initialValues, userId, removeSaveButton }: UserFinancialFormProps,
	ref: ForwardedRef<UserFormRef>
) {
	const { enqueueSnackbar } = useSnackbar();

	const [{ fetching: updateFetching, data: updateData, error: updateError }, updateUserFinancial] =
		useMutation<UserFinancialField>(UPSERT_USER_FINANCIAL);

	const methods = useForm<UserFinancial>({
		mode: "onBlur", // Must be onBlur for first-login to trigger it's ref properly
		resolver: yupResolver(UserFinancialSchema),
		defaultValues: initialValues ?? { iban: "", bic: "", country: "", user_id: userId },
	});

	const {
		reset,
		handleSubmit,
		formState: { isSubmitting, isDirty, isValid },
	} = methods;

	const onSubmit = async (finances: UserFinancial) => {
		await updateUserFinancial({
			object: { ...finances, user_id: userId },
		});
		reset({ ...finances });
	};

	useEffect(() => {
		if (updateData) {
			enqueueSnackbar("Financiële gegevens succesvol bijgewerkt");
		}
	}, [updateData]);

	useEffect(() => {
		if (updateError) {
			enqueueSnackbar(
				"Er is een fout opgetreden bij het wijzigen van de persoonlijke gegevens. Probeer het later opnieuw.",
				{ variant: "error" }
			);
		}
	}, [updateError]);

	useImperativeHandle(ref, () => ({
		isValid,
		submit: handleSubmit(onSubmit),
	}));

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Card sx={{ p: 3 }}>
				<Typography variant="h6" sx={{ pb: 2 }}>
					Financiële gegevens
				</Typography>
				<Box
					sx={{
						display: "grid",
						rowGap: 3,
						columnGap: 2,
						gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" },
					}}
				>
					<RHFTextField<UserFinancial> name="bic" label="BIC" required />
					<RHFTextField<UserFinancial> name="country" label="Land" required />
				</Box>
				<Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
					<RHFTextField<UserFinancial> name="iban" label="IBAN" required />
					{!removeSaveButton && (
						<LoadingButton
							type="submit"
							variant="contained"
							loading={isSubmitting || updateFetching}
							disabled={!isValid || !isDirty}
						>
							Opslaan
						</LoadingButton>
					)}
				</Stack>
			</Card>
		</FormProvider>
	);
}

const UserFinancialForm = forwardRef<UserFormRef, UserFinancialFormProps>(Financial);
export default UserFinancialForm;
