// This file configures the initialization of Sentry on the browser.
// The config you add here will be used whenever a page is visited.
// https://docs.sentry.io/platforms/javascript/guides/nextjs/

import * as Sentry from "@sentry/nextjs";

const SENTRY_DSN = process.env.SENTRY_DSN || process.env.NEXT_PUBLIC_SENTRY_DSN;

Sentry.init({
	dsn: SENTRY_DSN || "https://c5edef34705448e6bb90c6b42e62facf@o1008570.ingest.sentry.io/6684665",
	// Adjust this value in production, or use tracesSampler for greater control
	environment: process.env.SENTRY_ENVIRONMENT,
	tracesSampleRate: 0.3,
	enabled: process.env.NODE_ENV === "production",
	// Note: if you want to override the automatic release value, do not set a
	// `release` value here - use the environment variable `SENTRY_RELEASE`, so
	// that it will also get attached to your source maps
});
