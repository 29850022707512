export const getDirtyFields = <T>(data: T, dirtyFields: Record<Extract<keyof T, "string">, any>) => {
	const values: Partial<T> = {};
	for (const key of Object.keys(dirtyFields)) {
		const formattedKey = key as Extract<keyof T, "string">;
		values[formattedKey] = data[formattedKey];
	}
	if (Object.keys(values).length === 0 && Object.getPrototypeOf(values) === Object.prototype) {
		return;
	}
	return values;
};
