import BugReportIcon from "@mui/icons-material/BugReport";
import { Button } from "@mui/material";

import { BUG_REPORT_URL } from "src/layouts/routes";

export default function BugReport() {
	return (
		<Button
			fullWidth
			size="large"
			variant="outlined"
			color="inherit"
			startIcon={<BugReportIcon />}
			href={BUG_REPORT_URL}
			target="_blank"
			sx={{
				fontSize: 14,
			}}
		>
			Bug melden!
		</Button>
	);
}
