import { CommonUserFields, EnumFields, MutateFields } from "src/graphql/GeneralGraphql";
import { gql } from "urql";

export const ScheduleExceptionFields = `
	id
	user_id
	approved
	end_datetime
	updated_end_datetime
	description
	hours
	start_datetime
	userByUserId {
		${CommonUserFields}
	}
	userByReviewedBy {
		${CommonUserFields}
	}
	schedule_exception_type {
		${EnumFields}
	}
	${MutateFields}
`;

export const UPDATE_SCHEDULE_EXCEPTION = gql`
	mutation UPDATE_SCHEDULE_EXCEPTION(
		$_set: schedule_schedule_exception_set_input!
		$pk_columns: schedule_schedule_exception_pk_columns_input!
	) {
		update_schedule_schedule_exception_by_pk(_set: $_set, pk_columns: $pk_columns) {
			${ScheduleExceptionFields}
		}
	}
`;

export const SCHEDULE_EXCEPTION = gql`
	query SCHEDULE_EXCEPTION(
		$limit: Int!
		$offset: Int!
		$where: schedule_schedule_exception_bool_exp
		$order_by: [schedule_schedule_exception_order_by!]
	) {
		schedule_schedule_exception(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
			${ScheduleExceptionFields}
		}
	}
`;

export const CREATE_ABSENCE = gql`
	mutation CREATE_ABSENCE($object: schedule_schedule_exception_insert_input!) {
		insert_schedule_schedule_exception_one(object: $object) {
			${ScheduleExceptionFields}
		}
	}
`;

export const GET_ABSENCE_ENUMS = (arrayOperator: "_in" | "_nin") => gql`
	query GET_ABSENCE_ENUMS($operator: [String!]!) {
		schedule_schedule_exception_type(where: { name: { ${arrayOperator}: $operator } }, order_by: { id: asc }) {
			${EnumFields}
		}
		employee_user {
    	${CommonUserFields}
  	}
	}
`;

export const DELETE_ABSENCE = gql`
	mutation DELETE_ABSENCE($id: Int!) {
		delete_schedule_schedule_exception_by_pk(id: $id) {
			${ScheduleExceptionFields}
		}
	}
`;

export const GET_SICKNESS_START_DATE = gql`
	query GET_SICKNESS_START_DATE($id: Int!) {
		schedule_schedule_exception_by_pk(id: $id) {
			start_datetime
		}
	}
`;

export const GET_CURRENTLY_SICK_ABSENCES = gql`
	query GET_CURRENTLY_SICK_ABSENCES($user_id: String!) {
		schedule_schedule_exception(
			where: {
				user_id: { _eq: $user_id }
				updated_end_datetime: { _is_null: true }
				schedule_exception_type: { name: { _iregex: "verzuim" } }
			}
			order_by: { created_at: desc }
		) {
			id
			start_datetime
			schedule_exception_type {
				${EnumFields}
			}
		}
	}
`;

export const GET_ABSENCE_CALCULATION_FIELDS = gql`
	query GET_ABSENCE_CALCULATION_FIELDS($company_setting_id: Int = 1, $user_id: String!) {
		setting_by_pk(id: $company_setting_id) {
			id
			company_name
			default_full_time
		}
		employee_user_details_by_pk(user_id: $user_id) {
			active_contract_balance_fields
		}
	}
`;
