import { Box, Dialog, DialogProps, Paper, PaperProps } from "@mui/material";
import { AnimatePresence, m } from "framer-motion";
import React from "react";

import { varFade } from "./variants";

export interface Props extends DialogProps {
	variants?: Record<string, unknown>;
	onClose?: VoidFunction;
}

const DialogAnimate = React.memo(
	({ open = false, variants, onClose, children, sx, ...other }: Props) => {
		const getPaperComponent = (props: PaperProps<"div", object>) => (
			<Box
				component={m.div}
				{...(variants ||
					varFade({
						distance: 120,
						durationIn: 0.32,
						durationOut: 0.24,
						easeIn: "easeInOut",
					}).inUp)}
				sx={{
					width: "100%",
					height: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box onClick={onClose} sx={{ width: "100%", height: "100%", position: "fixed" }} />
				<Paper sx={sx} {...props}>
					{props.children}
				</Paper>
			</Box>
		);

		return (
			<AnimatePresence>
				{open && (
					<Dialog fullWidth maxWidth="xs" open={open} onClose={onClose} PaperComponent={getPaperComponent} {...other}>
						{children}
					</Dialog>
				)}
			</AnimatePresence>
		);
	},
	(previous, next) => previous.open === next.open
);
export default DialogAnimate;
