import { Stack, Link as MuiLink, Tooltip } from "@mui/material";
import AnyAvatar from "./AnyAvatar";
import Link from "next/link";

type AvatarCardProps = {
	name: string;
	picture: string | null;
	linkToUser?: string;
};

export const AvatarCard = ({ name, picture, linkToUser }: AvatarCardProps) => (
	<Stack direction="row" gap={1} alignItems="center">
		{name && <AnyAvatar name={name} picture={picture} />}
		{linkToUser ? (
			<Link href={linkToUser}>
				<Tooltip arrow title={"Open profiel"}>
					<MuiLink href={"#"}>{name}</MuiLink>
				</Tooltip>
			</Link>
		) : (
			name
		)}
	</Stack>
);
