import { RHFTextField, FormProvider, RHFDatePicker, RHFAutocomplete } from "@components/hook-form";
import { LoadingButton } from "@mui/lab";
import { Grid, Stack, Card, Box, Typography, InputAdornment, Tooltip } from "@mui/material";
import { useSnackbar } from "notistack";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { UserDetailsField, UserDetail, UsersField, Manager } from "src/type/user";
import { getDirtyFields } from "@utils/rhf";
import { useMutation, useQuery } from "urql";
import { ForwardedRef, forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { IconButtonAnimate } from "@components/animate";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { GET_MANAGER_OPTIONS, UPDATE_USER_DETAILS } from "../GeneralGraphql";
import { UserFormRef } from "../ProfileContainer";
import { UserDetailsSchema } from "./ProfileGeneralSchemas";
import { AvatarCard } from "@components/avatar/AvatarCard";

type UserDetailFormProps = {
	initialValues: UserDetail | null;
	userId: string;
	removeSaveButton?: boolean;
};

export type UserDetailFormInput = Pick<
	UserDetail,
	"employee_number" | "birth_date" | "salary" | "userByManager" | "seniority_date"
>;

function Detail({ initialValues, userId, removeSaveButton }: UserDetailFormProps, ref: ForwardedRef<UserFormRef>) {
	const { enqueueSnackbar } = useSnackbar();
	const [showSalary, setShowSalary] = useState(false);
	const handleShowSalary = () => setShowSalary(!showSalary);

	const [{ fetching: updateFetching, data: updateData, error: updateError }, updateUserDetails] =
		useMutation<UserDetailsField>(UPDATE_USER_DETAILS);

	const [{ data: managerOptionsData, fetching: managerOptionsFetching }] = useQuery<UsersField>({
		query: GET_MANAGER_OPTIONS,
	});

	const defaultValues = {
		address: initialValues?.address ?? "",
		birth_country: initialValues?.birth_country ?? "",
		bsn: initialValues?.bsn ?? "",
		city: initialValues?.city ?? "",
		country: initialValues?.country ?? "",
		free_text: initialValues?.free_text ?? "",
		full_first_name: initialValues?.full_first_name ?? "",
		initials: initialValues?.initials ?? "",
		marital_status: initialValues?.marital_status ?? "",
		name_prefix: initialValues?.name_prefix ?? "",
		nationality: initialValues?.nationality ?? "",
		postal_code: initialValues?.postal_code ?? "",
		province: initialValues?.province ?? "",
		salary: initialValues?.salary ?? null,
		sex: initialValues?.sex ?? "",
		employee_number: initialValues?.employee_number ?? -1,
		birth_date: initialValues?.birth_date ?? null,
		userByManager: initialValues?.userByManager ?? null,
		seniority_date: initialValues?.seniority_date ?? null,
	};

	useEffect(() => {
		reset(defaultValues);
	}, [initialValues?.user_id]);

	const methods = useForm<UserDetail>({
		mode: "onBlur", // Must be onBlur for first-login to trigger it's ref properly
		resolver: yupResolver(UserDetailsSchema),
		defaultValues,
	});

	const {
		reset,
		watch,
		handleSubmit,
		formState: { isSubmitting, isDirty, isValid, dirtyFields },
	} = methods;

	const onSubmit = async (details: UserDetail) => {
		const dirty = getDirtyFields<UserDetail>(details, dirtyFields);
		if (!dirty) return;
		if (dirty.userByManager) {
			dirty.manager = dirty.userByManager.id;
			delete dirty.userByManager;
		}
		if (dirty.userByManager === null) {
			dirty.manager = null;
			delete dirty.userByManager;
		}
		if (!userId) return;
		// Don't make this an upsert, the user should be created first via the create user form.
		// (Mostly for the employee number and employee_user_details dependencies in other components)
		await updateUserDetails({ user_id: userId, _set: dirty });
		reset({ ...details });
	};

	useEffect(() => {
		if (updateData) {
			enqueueSnackbar("Persoonlijke gegevens succesvol bijgewerkt");
		}
	}, [updateData]);

	useEffect(() => {
		if (updateError) {
			enqueueSnackbar(
				"Er is een fout opgetreden bij het wijzigen van de persoonlijke gegevens. Probeer het later opnieuw.",
				{ variant: "error" }
			);
		}
	}, [updateError]);

	useImperativeHandle(ref, () => ({
		isValid,
		submit: handleSubmit(onSubmit),
	}));

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<Grid container spacing={3} pt={3} display="grid" rowGap={3}>
				<Grid item xs={12} md={12}>
					<Card sx={{ p: 3 }}>
						<Typography variant="h6" sx={{ pb: 2 }}>
							Persoonlijke gegevens
						</Typography>
						<Box
							sx={{
								display: "grid",
								rowGap: 3,
								columnGap: 2,
								gridTemplateColumns: { xs: "repeat(1, 1fr)", sm: "repeat(2, 1fr)" },
							}}
						>
							{/* // TODO: Check & add autocomplete forms, seems to be buggy in this component. https://www.chromium.org/developers/design-documents/create-amazing-password-forms/ */}
							<RHFTextField<UserDetail> name="employee_number" label="Personeelsnummer *" type="number" />
							<RHFTextField<UserDetail> name="address" label="Adres" />
							<RHFTextField<UserDetail> name="city" label="Woonplaats" />
							<RHFTextField<UserDetail> name="postal_code" label="Postcode" />
							<RHFTextField<UserDetail> name="province" label="Provincie" />
							<RHFTextField<UserDetail> name="country" label="Land" />
							<RHFTextField<UserDetail> name="bsn" label="Burgerservicenummer" />
							<RHFTextField<UserDetail> name="initials" label="Initialen" />
							<RHFTextField<UserDetail> name="full_first_name" label="Volledige voornaam" />
							<RHFTextField<UserDetail> name="name_prefix" label="Tussenvoegsel" />
							<RHFDatePicker<UserDetail> name="birth_date" label="Geboortedatum" />
							<RHFTextField<UserDetail> name="sex" label="Geslacht" />
							<RHFAutocomplete<UserDetail, Manager>
								isOptionEqualToValue={(option, value) => option.id === value.id}
								getOptionLabel={(option) => (option as Manager)?.full_name}
								disablePortal={false}
								loading={managerOptionsFetching}
								options={managerOptionsData?.employee_user ?? []}
								name="userByManager"
								label="Leidinggevende"
								renderOption={(props, option) => (
									<li {...props} key={option.id}>
										<AvatarCard name={option.full_name} picture={option.picture} />
									</li>
								)}
							/>
							<RHFTextField<UserDetail> name="nationality" label="Nationaliteit" />
							<RHFTextField<UserDetail> name="marital_status" label="Burgerlijke staat" />
							<RHFTextField<UserDetail>
								name="salary"
								label="Bruto maandsalaris"
								type={showSalary ? "text" : "password"}
								InputLabelProps={{
									shrink: true,
								}}
								InputProps={{
									endAdornment: (
										<Tooltip arrow title={showSalary ? "Verberg salaris" : "Toon salaris"}>
											<InputAdornment position="end">
												<IconButtonAnimate onClick={handleShowSalary}>
													{showSalary ? <VisibilityIcon /> : <VisibilityOffIcon />}
												</IconButtonAnimate>
											</InputAdornment>
										</Tooltip>
									),
								}}
							/>
							<RHFDatePicker<UserDetail> name="seniority_date" label="Anciënniteitsdatum" />
						</Box>

						<Stack spacing={3} alignItems="flex-end" sx={{ mt: 3 }}>
							<RHFTextField<UserDetail> name="free_text" multiline rows={2} label="Omschrijving" />
							{!removeSaveButton && (
								<LoadingButton
									type="submit"
									variant="contained"
									loading={isSubmitting || updateFetching}
									disabled={!isDirty}
								>
									Opslaan
								</LoadingButton>
							)}
						</Stack>
					</Card>
				</Grid>
			</Grid>
		</FormProvider>
	);
}

const UserDetailForm = forwardRef<UserFormRef, UserDetailFormProps>(Detail);
export default UserDetailForm;
