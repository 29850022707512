import { useAuth, useOrganization, useOrganizationList, useUser } from "@clerk/nextjs";
import { GET_CURRENTLY_SICK_ABSENCES } from "@features/profile/absence/AbsenceGraphql";
import { useRouter } from "next/router";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { ROUTES } from "src/layouts/routes";
import { GET_CURRENTLY_SICK_ABSENCES_QUERY } from "src/type/schedule";
import { useQuery } from "urql";

/**
 * Checks if the user is currently marked as sick.
 * @param children
 * @returns null | children
 */
export function UserIsSick({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { user } = useUser();
	const { enqueueSnackbar } = useSnackbar();

	if (!user) return null;

	const [{ data: sicknessData, error: sicknessError }] = useQuery<GET_CURRENTLY_SICK_ABSENCES_QUERY>({
		query: GET_CURRENTLY_SICK_ABSENCES,
		variables: {
			user_id: user?.id,
		},
	});

	useEffect(() => {
		if (sicknessError) {
			enqueueSnackbar(`Er is een fout opgetreden bij ophalen van huidige verzuim. Probeer het later opnieuw.`, {
				variant: "error",
			});
		}
	}, [sicknessError]);

	if (!sicknessData?.schedule_schedule_exception.length) return null;
	// TODO: Use metadata instead of regular Hasura calls?
	// if (!user.publicMetadata?.firstLogin) return null;
	return <>{children}</>;
}

/**
 * Checks if the user has not logged in before and not handled the first login flow.
 * @param children
 * @returns null | children
 */
export function FirstLogin({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { user } = useUser();
	if (!user) return null;
	if (!user.publicMetadata?.firstLogin) return null;
	return <>{children}</>;
}

/**
 * Checks if the user has logged in before and handled the first login flow.
 * @param children
 * @returns null | children
 */
export function RegularLogin({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { user } = useUser();
	if (!user) return null;
	if (user.publicMetadata?.firstLogin) return null;
	return <>{children}</>;
}

/**
 * Checks whether an organization is active.
 * @param children
 * @returns null | children
 */
export function OrganizationActive({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { organization } = useOrganization();
	const { getToken } = useAuth();

	// Workaround NextJS SSR session token update bug.
	useEffect(() => {
		getToken({ skipCache: true });
	}, [organization]);

	if (organization) {
		return <>{children}</>;
	}
	return null;
}

/**
 * Checks whether an organization is inactive.
 * @param children
 * @returns null | children
 */
export function OrganizationInactive({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { organization } = useOrganization();

	if (!organization) {
		return <>{children}</>;
	}
	return null;
}

/**
 * Checks whether the user is assigned to any organization.
 * If the user is assigned to a single organization, automatically sets this organization as active.
 *
 * @param children
 * @returns null | children
 */
export function OrganizationAvailable({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { organizationList, setActive, isLoaded } = useOrganizationList();

	useEffect(() => {
		if (
			isLoaded && // If there is 1 organization present, set default active
			organizationList?.length === 1
		) {
			setActive({ organization: organizationList[0].organization.id });
		}
	}, [organizationList, isLoaded]);

	if (!isLoaded) {
		return null;
	}

	if (organizationList?.length > 1) {
		return <>{children}</>;
	}
	return null;
}

/**
 * Checks whether the user is not assigned to any organization.
 * @param children
 * @returns null | children
 */
export function OrganizationNotAvailable({ children }: React.PropsWithChildren<unknown>): JSX.Element | null {
	const { organizationList } = useOrganizationList();

	if (!organizationList?.length) {
		return <>{children}</>;
	}
	return null;
}

/**
 * Redirects user to 503 page
 * @returns null
 */
export function RedirectTo503(): null {
	const { push } = useRouter();
	useEffect(() => {
		push(ROUTES[503].root);
	}, []);
	return null;
}

/**
 * Redirects user to sign in page
 * @returns null
 */
export function RedirectToSignIn(): null {
	const { push } = useRouter();
	useEffect(() => {
		push(ROUTES.auth.signin);
	}, []);
	return null;
}

/**
 * Redirects user to select organization page
 * @returns null
 */
export function RedirectToSelectOrg(): null {
	const { push } = useRouter();
	useEffect(() => {
		push(ROUTES.auth.select_org);
	}, []);
	return null;
}
