import SettingsDrawer from "./drawer";
import ThemeColorPresets from "./ThemeColorPresets";
import ThemeContrast from "./ThemeContrast";

type Props = {
	children: React.ReactNode;
};

export default function ThemeSettings({ children }: Props) {
	return (
		<ThemeColorPresets>
			<ThemeContrast>
				{/* <ThemeLocalization> */}
				{/* // TODO: Reenable this if warranted */}
				{/* <ThemeRtlLayout> */}
				{children}
				<SettingsDrawer />
				{/* </ThemeRtlLayout> */}
				{/* </ThemeLocalization> */}
			</ThemeContrast>
		</ThemeColorPresets>
	);
}
