import { useUser } from "@clerk/nextjs";
import { yupResolver } from "@hookform/resolvers/yup";
import { LoadingButton } from "@mui/lab";
import { Button, DialogActions, DialogContent, DialogContentText, Stack } from "@mui/material";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { FormProvider } from "src/components/hook-form";
import RHFDateTimePicker from "src/components/hook-form/RHFDateTimePicker";
import { AbsenceFormType, ClearSicknessType, UPDATE_ABSENCE_MUTATION } from "src/type/schedule";
import { useMutation } from "urql";
import { UPDATE_SCHEDULE_EXCEPTION } from "./AbsenceGraphql";
import { SubmitSicknessEndSchema } from "./AbsenceSchema";

type SubmitSicknessEndFormProps = {
	absenceId: number;
	start_datetime: Date;
	onClose: (absence?: ClearSicknessType) => void;
};

export default function SubmitSicknessEndForm({ absenceId, start_datetime, onClose }: SubmitSicknessEndFormProps) {
	const { user } = useUser();
	const { enqueueSnackbar } = useSnackbar();

	// user?.update({unsafeMetadata: {sickness: false}}); // TODO: Update unsafe in frontend vs update public in tiny faas call

	const [{ fetching: updateAbsenceFetching, data: updateAbsenceData, error: updateAbsenceError }, updateAbsence] =
		useMutation<UPDATE_ABSENCE_MUTATION>(UPDATE_SCHEDULE_EXCEPTION);

	const methods = useForm<ClearSicknessType>({
		mode: "onSubmit",
		resolver: yupResolver(SubmitSicknessEndSchema),
		defaultValues: {
			start_datetime,
			updated_end_datetime: null,
		},
	});

	const {
		handleSubmit,
		reset,
		watch,
		formState: { isDirty },
	} = methods;

	const onSubmit = (data: ClearSicknessType) => {
		const object = {
			start_datetime: data.start_datetime,
			updated_end_datetime: data.updated_end_datetime,
		};
		updateAbsence({
			pk_columns: { id: absenceId },
			_set: object,
		});
	};

	useEffect(() => {
		if (updateAbsenceData) {
			enqueueSnackbar(`Succesvol beter gemeld`);
			handleCreate(updateAbsenceData.update_schedule_schedule_exception_by_pk);
		}
	}, [updateAbsenceData]);

	useEffect(() => {
		if (updateAbsenceError) {
			enqueueSnackbar(`Er is een fout opgetreden bij beter melden. Probeer het later opnieuw.`, {
				variant: "error",
			});
		}
	}, [updateAbsenceError]);

	const handleCreate = (absence?: ClearSicknessType) => {
		reset();
		onClose(absence);
	};

	const handleClose = () => {
		reset();
		onClose();
	};

	const values = watch();

	return (
		<FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
			<DialogContent>
				<Stack gap={3}>
					<DialogContentText>{`Vul hieronder de verzuimgegevens in.`}</DialogContentText>
					<RHFDateTimePicker<AbsenceFormType>
						name="start_datetime"
						label="Startdatum *"
						maxDate={values.updated_end_datetime ? new Date(values.updated_end_datetime) : undefined}
					/>
					<RHFDateTimePicker<AbsenceFormType>
						name="updated_end_datetime"
						label="Werkelijke einddatum *"
						minDate={values.start_datetime ? new Date(values.start_datetime) : undefined}
					/>
				</Stack>
			</DialogContent>
			<DialogActions sx={{ mr: 2.5, mb: 1 }}>
				<Button onClick={handleClose}>Annuleren</Button>
				<LoadingButton type="submit" disabled={!isDirty} variant="contained" loading={updateAbsenceFetching}>
					{"Beter melden"}
				</LoadingButton>
			</DialogActions>
		</FormProvider>
	);
}
