import { TextField, TextFieldProps } from "@mui/material";
import { DateTimePicker, LocalizationProvider } from "@mui/x-date-pickers-pro";
import { AdapterDateFns } from "@mui/x-date-pickers-pro/AdapterDateFns";
import deLocale from "date-fns/locale/de";
import enLocale from "date-fns/locale/en-US";
import frLocale from "date-fns/locale/fr";
import nlLocale from "date-fns/locale/nl";
import { Controller, useFormContext } from "react-hook-form";

type IProps<T> = {
	name: keyof T;
	locale?: "fr" | "nl" | "de" | "en";
	minDate?: Date;
	maxDate?: Date;
};

type Props<T> = IProps<T> & TextFieldProps;

const localeMap = {
	en: enLocale,
	fr: frLocale,
	de: deLocale,
	nl: nlLocale,
};

export default function RHFDateTimePicker<T>({ name, locale = "nl", minDate, maxDate, ...other }: Props<T>) {
	const { control, setValue } = useFormContext();

	return (
		<Controller
			name={name}
			control={control}
			render={({ field, fieldState: { error } }) => (
				<LocalizationProvider adapterLocale={localeMap[locale]} dateAdapter={AdapterDateFns}>
					<DateTimePicker
						ampm={false}
						ampmInClock={false}
						disabled={other.disabled}
						{...field}
						minDate={minDate}
						maxDate={maxDate}
						onChange={(date: Date | null) =>
							setValue(name, date as any, { shouldDirty: true, shouldValidate: true, shouldTouch: true })
						}
						renderInput={(params: TextFieldProps) => (
							<TextField {...params} fullWidth error={!!error} helperText={error?.message} {...other} />
						)}
					/>
				</LocalizationProvider>
			)}
		/>
	);
}
