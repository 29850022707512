import { IconButtonAnimate } from "@components/animate";
import { Box, ListItemIcon, ListItemText, MenuItem, Popover, Stack, Tooltip } from "@mui/material";
import { useRouter } from "next/router";
import { useState } from "react";
import Iconify from "@components/other/Iconify";
import useResponsive from "@hooks/useResponsive";

type DataGridActionsProps<T> = {
	object: T;
	actions: TableRowAction<T>[];
};

export type TableRowAction<T> = {
	name: string;
	label: string | ((object: T) => string);
	color?: "primary" | "secondary" | "success" | "error" | "info" | "warning" | "inherit" | "default";
	disabled: boolean | ((object: T) => boolean);
	icon?: React.ReactNode;
	link?: (id: T) => string;
	type?: "download" | "share";
	onClick?: (object: T) => void;
};

function DataGridActions<T>({ object, actions }: DataGridActionsProps<T>) {
	const { push } = useRouter();
	const isDesktop = useResponsive("up", "md");
	const [open, setOpen] = useState<HTMLElement | null>(null);

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		setOpen(event.currentTarget);
	};

	const handleClose = () => {
		setOpen(null);
	};

	// TODO: Re-add downloadFile from Opusflow when working on attachments (and AttachmentList.tsx for example)

	if (isDesktop) {
		return (
			<>
				<Stack direction="row" justifyContent="flex-end" gap={1} aria-label="DataGrid Actions">
					{actions.map((action) => (
						<Tooltip
							arrow
							key={action.name}
							title={typeof action.label === "function" ? action.label(object) : action.label}
						>
							<span>
								<IconButtonAnimate
									disabled={typeof action.disabled === "boolean" ? action.disabled : action.disabled(object) ?? false}
									color={action.color ?? "primary"}
									onClick={() => {
										if (action.onClick) {
											action.onClick(object);
										}
										if (action.link) {
											push(action.link(object));
										}
									}}
									size="small"
								>
									{action.icon}
								</IconButtonAnimate>
							</span>
						</Tooltip>
					))}
				</Stack>
			</>
		);
	}

	// TODO: Responsive mobile menu length (long texts don't fit atm)
	// TODO: Add colors to these menu items
	return (
		<Box>
			<IconButtonAnimate onClick={handleOpen}>
				<Iconify icon={"eva:more-vertical-fill"} sx={{ width: 20, height: 20 }} />
			</IconButtonAnimate>
			<Popover
				open={Boolean(open)}
				anchorEl={open}
				onClose={handleClose}
				sx={{
					p: 0,
					mt: 1.5,
					ml: 0.75,
					"& .MuiMenuItem-root": {
						typography: "body2",
						borderRadius: 0.75,
					},
				}}
			>
				{actions.map((action) => (
					<MenuItem
						disabled={typeof action.disabled === "boolean" ? action.disabled : action.disabled(object) ?? false}
						key={action.name}
						onClick={() => {
							if (action.onClick) {
								action.onClick(object);
							}
							if (action.link) {
								push(action.link(object));
							}
						}}
					>
						<ListItemIcon color={action.color ?? "primary"}>{action.icon}</ListItemIcon>
						<ListItemText>{typeof action.label === "function" ? action.label(object) : action.label}</ListItemText>
					</MenuItem>
				))}
			</Popover>
		</Box>
	);
}

export default DataGridActions;
