import { Localization, enUS, nlNL } from "@mui/material/locale";
import { LocaleObject } from "yup/lib/locale";

import { SettingsValueProps, SupportedLocales } from "./components/settings/type";

export const HASURA_CLAIMS = "https://hasura.io/jwt/claims";

export const HEADER = {
	MOBILE_HEIGHT: 64,
	MAIN_DESKTOP_HEIGHT: 88,
	DASHBOARD_DESKTOP_HEIGHT: 92,
	DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
	BASE_WIDTH: 260,
	DASHBOARD_WIDTH: 260,
	DASHBOARD_COLLAPSE_WIDTH: 88,
	//
	DASHBOARD_ITEM_ROOT_HEIGHT: 42,
	DASHBOARD_ITEM_SUB_HEIGHT: 34,
	DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 42,
};

export const ICON = {
	NAVBAR_ITEM: 22,
	NAVBAR_ITEM_HORIZONTAL: 20,
};

// SETTINGS

export const cookiesExpires = 3;

export const cookiesKey = {
	themeMode: "themeMode",
	themeLayout: "themeLayout",
	themeStretch: "themeStretch",
	themeContrast: "themeContrast",
	themeDirection: "themeDirection",
	themeColorPresets: "themeColorPresets",
};

export const defaultSettings: SettingsValueProps = {
	themeMode: "light",
	themeDirection: "ltr",
	themeContrast: "default",
	themeLayout: "horizontal",
	themeColorPresets: "blue",
	themeStretch: false,
	open: false,
};

// MULTI LANGUAGES

export type Language = {
	label: string;
	value: string;
	locale: SupportedLocales;
	systemValue: Localization;
	icon: string;
	path: string;
};

export const LANGUAGES: Language[] = [
	{
		label: "Nederlands",
		value: "nl",
		locale: "nlNL",
		systemValue: nlNL,
		icon: "/assets/icons/flags/ic_flag_nl.svg",
		path: "nl",
	},
	{
		label: "English",
		locale: "enUS",
		value: "en",
		systemValue: enUS,
		icon: "/assets/icons/flags/ic_flag_en.svg",
		path: "en",
	},
];

export const defaultLang = LANGUAGES[0]; // Dutch

const getLabel = (label?: string) => label ?? "Veld";

const localizedYupTypes: Record<keyof LocaleObject, string> = {
	mixed: "gemengd",
	string: "tekst",
	number: "nummer",
	boolean: "waarheidswaarde",
	date: "datum",
	array: "lijst",
	object: "object",
};

const getLocalizedYupTypes = (type: keyof LocaleObject) => localizedYupTypes[type];

export const yupValidationLanguage: LocaleObject = {
	mixed: {
		default: (_params) => `Ongeldige waarde`,
		required: ({ label }) => `${getLabel(label)} is vereist`,
		oneOf: ({ label, values }) => `${getLabel(label)} moet één van de volgende woorden bevatten: ${values}`,
		notOneOf: ({ label, values }) => `${getLabel(label)} mag niet het volgende bevatten: ${values}`,
		notType: ({ label, type }) =>
			`${getLabel(label)} moet een ${getLocalizedYupTypes(type as keyof LocaleObject)} zijn`,
		defined: ({ label }) => `${getLabel(label)} is verplicht`,
	},
	string: {
		length: ({ label, length }) => `${getLabel(label)} moet ${length} karakters zijn`,
		min: ({ label, min }) => `${getLabel(label)} moet minimaal ${min} karakters bevatten`,
		max: ({ label, max }) => `${getLabel(label)} moet minder dan ${max} karakters bevatten`,
		matches: ({ label, regex }) => `${getLabel(label)} moet overeenkomen met het volgende: "${regex}"`,
		email: ({ label }) => `${getLabel(label)} moet een geldig e-mailadres zijn`,
		url: ({ label }) => `${getLabel(label)} moet een geldige URL zijn`,
		uuid: ({ label }) => `${getLabel(label)} moet een geldige UUID zijn`,
		trim: ({ label }) => `${getLabel(label)} mag voor of na geen spaties bevatten`,
		lowercase: ({ label }) => `${getLabel(label)} moet in kleine letters`,
		uppercase: ({ label }) => `${getLabel(label)} moet in hoofdletters`,
	},
	number: {
		min: ({ label, min }) => `${getLabel(label)} moet minimaal ${min} zijn`,
		max: ({ label, max }) => `${getLabel(label)} mag maximaal ${max} zijn`,
		lessThan: ({ label, less }) => `${getLabel(label)} moet kleiner zijn dan ${less}`,
		moreThan: ({ label, more }) => `${getLabel(label)} moet groter zijn dan ${more}`,
		positive: ({ label }) => `${getLabel(label)} moet een positief getal zijn`,
		negative: ({ label }) => `${getLabel(label)} moet een negatief getal zijn`,
		integer: ({ label }) => `${getLabel(label)} moet een getal zijn`,
	},
	date: {
		min: ({ label, min }) => `${getLabel(label)} moet later zijn dan ${min}`,
		max: ({ label, max }) => `${getLabel(label)} moet eerder zijn dan ${max}`,
	},
	boolean: {
		isValue: ({ label, value }) => `${getLabel(label)} moet ${value} zijn`,
	},
	object: {
		noUnknown: ({ value }) => `Onbekende key ${value}`,
	},
	array: {
		min: ({ label, min }) => `${getLabel(label)} moet minimaal ${min} items bevatten`,
		max: ({ label, max }) => `${getLabel(label)} mag maximaal ${max} items bevatten`,
		length: ({ label, length }) => `${getLabel(label)} moet precies ${length} items bevatten`,
	},
};
