import { Box, BoxProps } from "@mui/material";
import NextLink from "next/link";
import { forwardRef } from "react";

import useSettings from "src/hooks/useSettings";

interface Props extends BoxProps {
	disabledLink?: boolean;
	full?: boolean;
}

const Logo = forwardRef<any, Props>(({ disabledLink = false, full = false, sx }, _reference) => {
	const { themeMode } = useSettings();

	const logo = !full ? (
		<Box component="img" src="/logo/icon_round.webp" sx={{ width: 40, height: 40, cursor: "pointer", ...sx }} />
	) : (
		<Box
			component="img"
			src={themeMode === "dark" ? "/logo/profitflow_white.svg" : "/logo/profitflow_blue.svg"}
			sx={{ width: "100%", height: 40, paddingRight: 2, cursor: "pointer", ...sx }}
		/>
	);

	if (disabledLink) {
		return logo;
	}

	return <NextLink href="/">{logo}</NextLink>;
});

export default Logo;
