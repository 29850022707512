export const CommonUserFields = `
	id
	full_name
	picture
`;

export const BasicEnumFields = `
	id
	name
	comment
`;

export const EnumFields = `
	${BasicEnumFields}
	color_type {
		name
	}
`;

export const MutateFields = `
	created_at
	updated_at
	created_by
	updated_by
	userByUpdatedBy {
		${CommonUserFields}
	}
	user {
		${CommonUserFields}
	}
`;
